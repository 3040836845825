import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/module/menu/list", { params });
}

export function Add(params) {
  return request.post("sys/module/menu", params);
}

export function Delete(data) {
  return request.delete("sys/module/menu", { data });
}
