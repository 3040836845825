import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/module/list", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("sys/module" + params);
}

export function Add(params) {
  return request.post("sys/module", params);
}

export function Update(params) {
  return request.put("sys/module", params);
}

export function Delete(data) {
  return request.delete("sys/module", { data });
}

