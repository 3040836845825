<template>
  <div>
    <a-modal v-model:visible="visible" title="关联权限" @ok="visible = false" okText="完成" cancelText="取消" :afterClose="closeModal" :cancelButtonProps="{ style: { display: 'none' }}" width="55vw">
      <a-radio-group v-model:value="clientAppName" @change="detail" size="large" style="margin-bottom:20px;">
        <a-radio-button value="智慧中医">智慧中医</a-radio-button>
        <a-radio-button value="慧中医">慧中医</a-radio-button>
        <a-radio-button value="世道医护">世道医护</a-radio-button>
      </a-radio-group>
      <div class="maxHeight">
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel v-for="item in data " :key="item.name" :header="item.name+'（'+item.count+'）'">
            <div class="checkbox">
              <a-checkbox v-for="item2 in item.subMenus" :checked="item2.checked" @change="onChange($event,item2)">{{item2.name}}</a-checkbox>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Add, Search, Delete } from "/src/api/moduleMenu.js";
export default {
  components: {
  },
  data() {
    return {
      activeKey: "",
      visible: this.modelValue,
      visible2: false,
      reload: false,
      clientAppName: "智慧中医",
      selectedRows: {},
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "75%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      console.log(this.record);
      let res = await Search({ moduleId: this.record.id, type: "菜单", clientAppName: this.clientAppName })
      console.log(res);
      // console.log(res)
      if (res.errCode == 0) {
        res.data.forEach(item => {
          console.log(item.id);
          let length = item?.subMenus?.filter(item => item.relationId)?.length
          item.count = length == undefined ? 0 : length
          item?.subMenus?.forEach(item2 => {
            if (item2.relationId) {
              item2.checked = true
            } else {
              item2.checked = false
            }
          })
        })
        this.data = res.data
        if (!this.activeKey) {
          this.activeKey = this.data[0].name
        }

      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async onChange(e, target) {
      if (e.target.checked) {
        await Add({ moduleId: this.record.id, menuId: target.id, menuName: target.name })
        this.detail()
      } else {
        await Delete({ id: target.relationId })
        this.detail()

      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
}
.checkbox label {
  width: 20%;
  margin: 15px 10px;
}
.maxHeight {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
